import {Authenticator} from "@aws-amplify/ui-react";
import { Link } from 'react-router-dom'
import { useState} from "react";
import React from 'react';
import {useLocation, useNavigate} from "react-router-dom";

let site = sessionStorage.getItem('pageNo');
let loaded = 0;
let filename = "";
let binfile = "";
let data3;
let indexforinstituts = 0;
let options = [];
let optionsfilterbyid = [];

// obj from csv file
let obj = {filename: filename, file: binfile};
let changeNeeded = false;
let newpatient = false;

const  Process_page = () => {

    const navigate = useNavigate();



    const location = useLocation();
    let data_new = location.state;
    if (data3 == null) {
        data3 = data_new;
        sessionStorage.setItem('deleted', 0);
    }
    // if (data3 != null && sessionStorage.getItem('deleted') > 0) {
    //     data3 = data3;
    // }

    const fetchData = async () => {
        sessionStorage.setItem('pageNo', site);
        filename = data_new[site].name;
        binfile = atob(data_new[site].bin);
        const file = binfile;
        const lines = file.split('\n');
        // TODO CSV File is not possible to parse
        // if (obj.Patient_ID_from_csv === undefined || obj.EASEEPowerSN_from_csv === undefined) {
        //     window.alert("CSV File is not possible to parse! Call Support!");
        //     navigate('/');
        // }
        const line1 = lines[1].split(',')[1].replaceAll('"', '');
        const line2 = lines[2].split(',')[1].replaceAll('"', '');
        obj.filename = filename;
        obj.file = binfile;
        obj.Patient_ID_from_csv = line1;
        obj.EASEEPowerSN_from_csv = line2;

        if (loaded === 0) {
            try {

                // let raw = "{\r\n    \"patient\": {\r\n        \"patientIdByPhysician\": \"2113249\",\r\n        \"easeePowerSN\": \"2113249I\"\r\n    }\r\n}";
                // let raw = "{'patient':{'patientIdByPhysician':'" +
                //     sessionStorage.getItem('Patient_ID_from_csv') + "','easeePowerSN':'" +
                //     sessionStorage.getItem('EASEEPowerSN_from_csv') +
                //     "'}}"
                let raw = "{\r\n    \"patient\": {\r\n        \"patientIdByPhysician\": \""+obj.Patient_ID_from_csv+"\",\r\n        \"easeePowerSN\": \""+obj.EASEEPowerSN_from_csv+"\"\r\n    }\r\n}";
                const apiGatewayUrl = 'https://sxk30zdmj1.execute-api.eu-central-1.amazonaws.com/prod/getpatientidbyphysicians';

                const response = await fetch(apiGatewayUrl, {
                    method: 'POST',
                    body: raw,
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': '*',
                        'Access-Control-Allow-Headers': '*',
                    },
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                else {
                    let jsonData = await response.json();
                    let parsed_body = JSON.parse(jsonData.body);

                    if (jsonData['statusCode'] === 200 && jsonData['easeePowerSN_found'] === 'true') {
                        // Patient found by easeePowerSN!!
                        let doc = document.getElementById("subject_database")
                        doc.innerHTML = "Subject was found in database!"
                        let doc3 = document.getElementById("change_note")
                        doc3.innerHTML = "Subject was found by easeePowerSN in database. This means we were able to clearly identify the patient. You can change the patient using the change button at your own risk."
                        // TODO hier werden alle gleichen cloudGeneratedIDs angezeigt, hier wieder nicht geprüft ob es noch andere cloudGeneratedIDs gibt
                        setchanged_cloudGeneratedID(parsed_body[0]["cloudGeneratedID"])
                    }
                    if (jsonData['statusCode'] === 201 && jsonData['easeePowerSN_found'] === 'false' && jsonData['patientIdByPhysician_found'] === 'true') {
                        // Patient found by patientIdByPhysician it is possible that more than one has been found
                        let doc = document.getElementById("subject_database")
                        doc.innerHTML = "Subject was found in database!"
                        let doc3 = document.getElementById("change_note")
                        doc3.style.background = "red";
                        doc3.innerHTML = "Patient found by patientIdByPhysician it is possible that more than one has been found."
                        let button = document.createElement('button');
                        button.innerHTML = 'Change Patient by Subject ID';
                        button.className = 'rounded-button';
                        button.onclick = togglePopupWithFilter;
                        doc3.appendChild(button);
                        let button2 = document.createElement('button');
                        button2.innerHTML = 'Create new Patient';
                        button2.className = 'rounded-button';
                        button2.onclick = handleNewPatient;
                        doc3.appendChild(button2);
                        changeNeeded = true;
                        // <button className={"rounded-button"} onClick={handlediscardbutton}>Discard all changes</button>
                    }
                    if (jsonData['statusCode'] === 202 && jsonData['easeePowerSN_found'] === 'false' && jsonData['patientIdByPhysician_found'] === 'false') {
                        // No patient was found
                        let doc = document.getElementById("subject_database");
                        doc.innerHTML = "Subject not found in database!";
                        let doc3 = document.getElementById("change_note");
                        doc3.innerHTML = "No subject was found, you have the option to create a new one or select a patient using the change button.";
                        newpatient = true;
                    }

                    if (parsed_body.length !== 0) {
                        let lable = document.getElementById("label_patient_found_in_database");
                        const newlable = document.createElement("p");
                        newlable.innerHTML = "Implant SN";
                        lable.appendChild(newlable);

                        let data = document.getElementById("data_patient_found_in_database");
                        const newdata = document.createElement("p");
                        newdata.innerHTML = parsed_body[0]["easeePowerSN"];
                        data.appendChild(newdata);

                        for (let i = 0; i < parsed_body.length; i++) {
                            // let cloudGeneratedID = parsed_body[i]["cloudGeneratedID"];
                            // let easeePowerSN = parsed_body[i]["easeePowerSN"];
                            let name = parsed_body[i]["name"];
                            let patientIDbyPhysican = parsed_body[i]["patientIDbyPhysican"];

                            let lable1 = document.getElementById("label_patient_found_in_database");
                            const newlable1 = document.createElement("p");
                            newlable1.innerHTML = "Institution";
                            lable1.appendChild(newlable1);

                            let data1 = document.getElementById("data_patient_found_in_database");
                            const newdata1 = document.createElement("p");
                            newdata1.innerHTML = name;
                            data1.appendChild(newdata1);

                            let lable2 = document.getElementById("label_patient_found_in_database");
                            const newlable2 = document.createElement("p");
                            newlable2.innerHTML = "Subject ID";
                            lable2.appendChild(newlable2);

                            let data2 = document.getElementById("data_patient_found_in_database");
                            const newdata2 = document.createElement("p");
                            newdata2.innerHTML = patientIDbyPhysican;
                            data2.appendChild(newdata2);

                        }
                    }

                    loaded++;
                }

            } catch (error) {
                console.error('Fehler beim Abrufen der Daten:', error);
                let doc = document.getElementById("subject_database")
                doc.innerHTML = "Error while searching in Database!"
            }

            // Alle Patientien für den Change Button holen
            try {
                const response = await fetch("https://sxk30zdmj1.execute-api.eu-central-1.amazonaws.com/prod/getallpatients", {
                    method: 'GET',
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': '*',
                        'Access-Control-Allow-Headers': '*',
                    },
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                else {
                    const jsonData = await response.json();
                    const parsed_body = JSON.parse(jsonData.body);
                    for (let i = 0; i < parsed_body.length; i++) {
                        options.push({value: parsed_body[i][0]+"#"+parsed_body[i][3], label: "  easeePowerSN: "+parsed_body[i][2]+" Subject ID: "+parsed_body[i][3]+" Institute: "+parsed_body[i][4]+" Type: "+parsed_body[i][5]+" Address: "+parsed_body[i][6]});
                    }
                }
            } catch (error) {
                console.error('Fehler beim Abrufen der Daten:', error);
            }


        }



    };

    if (data3) {
        if (site == null) {
            site = 0;
        }
        fetchData();
    }

    const handleSaveClick = async () => {
        let cloudGeneratedID = "";
        // const post_link = "https://sxk30zdmj1.execute-api.eu-central-1.amazonaws.com/prod/uploadcsv?Institution_affilitaionStatus=1&EASEEPowerImplant_status=2&Institution_institutionID="+value;
        // console.log(post_link);
        let post_link = "";

        const fields = checkAddedFields();
        let institutes_ids = "";
        let subjects_ids = "";
        if (fields != null) {
            institutes_ids = fields[0];
            subjects_ids = fields[1];
            if (changeNeeded === true && chosenValue === "") {
                window.alert("You need to select some subject or implant sn! Or Click new Patient");
            }
            else {
                if (changeNeeded === false && chosenValue !== "" && newpatient === false) {
                    // Changed Subject
                    cloudGeneratedID = changed_cloudGeneratedID;
                }
                if (changeNeeded === false && chosenValue === "" && newpatient === false) {
                    // Append to cloudGeneratedID while Patient_ID_from_csv != Patient_ID_from_Database
                    cloudGeneratedID = changed_cloudGeneratedID;
                }
                if (changeNeeded === true && chosenValue === "" && newpatient === true) {
                    // new Subject but if click new patient
                    cloudGeneratedID = obj.Patient_ID_from_csv + obj.EASEEPowerSN_from_csv
                }
                if (changeNeeded === false && chosenValue === "" && newpatient === true) {
                    // new Subject
                    cloudGeneratedID = obj.Patient_ID_from_csv + obj.EASEEPowerSN_from_csv
                }

                const confirm = window.confirm('Confirm Upload!');
                if (confirm === true) {
                    if (chosenValue !== "") {
                        post_link = "https://sxk30zdmj1.execute-api.eu-central-1.amazonaws.com/prod/uploadcsv?cloudGeneratedID=" + cloudGeneratedID + "&institutes=" + institutes_ids + "&subjects=" + subjects_ids + "&changed_subject=true";
                    }
                    else {
                        post_link = "https://sxk30zdmj1.execute-api.eu-central-1.amazonaws.com/prod/uploadcsv?cloudGeneratedID=" + cloudGeneratedID + "&institutes=" + institutes_ids + "&subjects=" + subjects_ids + "&changed_subject=false";
                    }
                    let response = "";
                    try {
                        response = await fetch(post_link, {
                            method: 'POST',
                            body: binfile,
                            headers: {
                                "Content-Type": "multipart/form-data",
                                "Access-Control-Allow-Origin": "*",
                                "Access-Control-Allow-Methods": "*",
                                "Access-Control-Allow-Headers": "*"
                            },
                        });
                        if (!response.ok) {
                            throw new Error(`HTTP error! status: ${response.status}`);
                        }
                    } catch (error) {
                        console.error('Error uploading file:', error);
                    }
                    const jsonData = await response.json();
                    if (jsonData.errorMessage) {
                        window.alert("Error uploading the CSV, please send this file with the error message to: pascal@aviselabs.de");
                        // window.alert("Error: "+jsonData.stackTrace[0]);
                        console.log(jsonData);
                    }
                    else {
                        if (jsonData["statusCode"] === 999) {
                            console.log(jsonData);
                            window.alert("Error uploading the CSV, please send this file with the error message to: pascal@aviselabs.de!");
                        }
                        if (jsonData["statusCode"] === 409) {
                            // console.log(jsonData);
                            window.alert("CSV File already exists!");
                        }
                        if (jsonData["statusCode"] === 200) {
                            if (data3) {
                                if (data_new.length === 1) {
                                    // console.log(data_new);
                                    document.getElementById('addInformations').style.display='none';
                                    document.getElementById("addInformations_label").innerHTML = "";
                                    document.getElementById("addInformations_input").innerHTML = "";
                                    document.getElementById('label_patient_found_in_database').innerHTML="";
                                    document.getElementById('data_patient_found_in_database').innerHTML="";
                                    window.alert(jsonData["body"]);
                                    // console.log(jsonData);
                                    site = 0;
                                    filename = "";
                                    handleNavigation('/');
                                }
                                if (data_new.length > 1) {
                                    // console.log(data3);
                                    data3.splice(site, 1);
                                    document.getElementById('addInformations').style.display='none';
                                    document.getElementById("addInformations_label").innerHTML = "";
                                    document.getElementById("addInformations_input").innerHTML = "";
                                    document.getElementById('label_patient_found_in_database').innerHTML="";
                                    document.getElementById('data_patient_found_in_database').innerHTML="";
                                    // console.log(data3);
                                    site = 0;
                                    window.alert(jsonData["body"]);
                                    // console.log(jsonData);
                                    loaded = 0;
                                    navigate("/process_page", { state: data3 });
                                }
                            }
                        }
                    }
                } else {
                    window.alert("Not confirmed!")
                }
            }
        }
    };

    const handleDeleteClick = () => {
        let check = window.confirm('Remove file?');

        if (check === true) {

            if (data3) {
                if (data_new.length === 1) {
                    site = 0;
                    filename = "";
                    handleNavigation('/');
                }
                if (data_new.length > 1) {
                    data3.splice(site, 1);
                    document.getElementById('addInformations').style.display='none';
                    document.getElementById("addInformations_label").innerHTML = "";
                    document.getElementById("addInformations_input").innerHTML = "";
                    document.getElementById('label_patient_found_in_database').innerHTML="";
                    document.getElementById('data_patient_found_in_database').innerHTML="";
                    site = 0;
                    navigate("/process_page", { state: data3 });
                }
            }
        }
    };

    function checkAddedFields() {
        // animiere();
        const getallselections = document.getElementsByClassName("select");
        const getallinputs = document.getElementsByClassName("input");
        if (getallselections.length === 0 && getallinputs.length === 0) {
            // window.confirm("No institute selected, do you still want to upload the file?")
            window.alert("No institute selected!")
        }
        else {
            let ids_select = '';
            let index_select = 0;
            for(let i = 0; i < getallselections.length; i++) {
                let id = document.getElementById(getallselections[i].id)
                if (getallselections[i].value === "null") {
                    id.style.background = "red";
                    // window.alert("No institute selected!")
                }
                else {
                    id.style.background = "transparent";
                    ids_select += getallselections[i].value+" ";
                    index_select++;
                }
            }
            let ids_input = '';
            let index_input = 0;
            for(let i = 0; i < getallinputs.length; i++) {
                let id = document.getElementById(getallinputs[i].id)
                if (getallinputs[i].value === "") {
                    id.style.background = "red";
                    // window.alert("No input for subject id!")
                }
                else {
                    id.style.background = "transparent";
                    ids_input += getallinputs[i].value+" ";
                    index_input++;
                }
            }
            // check selection and input
            if (ids_select !== '' && ids_input !== '' && index_select % index_input === 0 && index_select === getallselections.length && index_input === getallinputs.length) {
                // delete last char ;
                ids_select = ids_select.slice(0,-1);
                ids_input = ids_input.slice(0,-1);

                // console.log(ids_select);
                // console.log(ids_input);
                return [ids_select, ids_input];

            }

        }
    }

    const handlePreviousClick = () => {
        if (data_new) {
            if (site > 0) {
                site--;
                fetchData();
                loaded = 0;
                document.getElementById('addInformations').style.display='none';
                document.getElementById("addInformations_label").innerHTML = "";
                document.getElementById("addInformations_input").innerHTML = "";
                document.getElementById('label_patient_found_in_database').innerHTML="";
                document.getElementById('data_patient_found_in_database').innerHTML="";
                navigate("/process_page", { state: data3 });
                return true;
            }
        }
        return false;
    }

    const handleNextClick = () => {
        if (data_new) {
            // check end of array
            if (site < data_new.length-1) {
                site++;
                fetchData();
                loaded = 0;
                document.getElementById('addInformations').style.display='none';
                document.getElementById("addInformations_label").innerHTML = "";
                document.getElementById("addInformations_input").innerHTML = "";
                document.getElementById('label_patient_found_in_database').innerHTML="";
                document.getElementById('data_patient_found_in_database').innerHTML="";
                navigate("/process_page", { state: data3 });
                return true;
            }
        }
        return false;
    }

    const handelPlusPatientClick = () => {
        document.getElementById('addInformations').style.display='block';
        let lable = document.getElementById("addInformations_label");
        const newlable = document.createElement("p");
        newlable.innerHTML = "Subject ID";
        lable.appendChild(newlable);

        let textfield = document.getElementById("addInformations_input");
        const input = document.createElement("input");
        input.id = "input-subject-id-"+indexforinstituts;
        input.className = "input";
        input.style.width = "222px";
        input.style.background = "transparent";
        input.style.border = "2px solid #A5B9C3";
        input.style.paddingLeft = "5px";
        input.style.paddingRight = "5px";
        input.style.color = "#fff";
        input.value = obj.Patient_ID_from_csv;
        indexforinstituts++;
        textfield.appendChild(input);

    }

    // Initialisiert den State mit dem Anfangswert des Dropdowns
    const [selectedValues, setSelectedValues] = useState({
        dropdown1: '',
        dropdown2: '',
        dropdown3: ''
    });

    // Handler, der aufgerufen wird, wenn ein neuer Wert ausgewählt wird
    const handleSelectedInstitute = (event, dropdownKey) => {
        setSelectedValues({
            ...selectedValues,
            [dropdownKey]: event.target.value
        });
        // console.log("Test: "+dropdownKey+ " " +event.target.value)
    }

    function loadInstitutestype1() {
        const button = document.getElementById("plusInstitution");
        // Disable the button
        button.disabled = false;
        // const para = document.createElement("p");
        // para.innerHTML = "This is a paragraph.";
        // document.getElementById("institutes_list").appendChild(para);
        document.getElementById('addInformations').style.display='block';
        let lable = document.getElementById("addInformations_label");
        const newlable = document.createElement("p");
        newlable.innerHTML = "Precisis";
        lable.appendChild(newlable);

        let selectoption = document.getElementById("addInformations_input");
        const select = document.createElement("select");
        select.id = "select-institute-id-"+indexforinstituts;
        select.className = "select";
        select.style.width = "222px";
        select.style.background = "transparent";
        select.style.border = "2px solid #A5B9C3";
        select.style.color = "#fff";
        select.style.paddingLeft = "5px";
        select.style.paddingRight = "5px";
        select.value = selectedValues.dropdown1;
        select.onchange = (e) => handleSelectedInstitute(e, 'dropdown1');
        selectoption.appendChild(select);


        const fetchData = async () => {
            const raw = JSON.stringify({
                "type": "Precisis"
            });
            try {
                const response = await fetch("https://sxk30zdmj1.execute-api.eu-central-1.amazonaws.com/prod/getallinstitutes", {
                    method: 'POST',
                    body: raw,
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': '*',
                        'Access-Control-Allow-Headers': '*',
                    },
                });
                const jsonData = await response.json();
                const parsed_body = JSON.parse(jsonData.body);
                let nulloption = document.createElement("option");
                nulloption.value = null;
                nulloption.text = "Select Institute";
                nulloption.style.color = "black";
                select.appendChild(nulloption);
                for (let i = 0; i < parsed_body.length; i++) {
                    let option = document.createElement("option");
                    option.value = parsed_body[i]["institutionID"];
                    option.text = parsed_body[i]["name"];
                    option.style.color = "black";
                    select.appendChild(option);
                }
            } catch (error) {
                console.error('Fehler beim Abrufen der Daten:', error);
            }
        };
        handelPlusPatientClick();
        fetchData();
    }

    function loadInstitutestype2() {
        const button = document.getElementById("plusInstitution");
        // Disable the button
        button.disabled = false;
        // const para = document.createElement("p");
        // para.innerHTML = "This is a paragraph.";
        // document.getElementById("institutes_list").appendChild(para);
        document.getElementById('addInformations').style.display='block';
        let lable = document.getElementById("addInformations_label");
        const newlable = document.createElement("p");
        newlable.innerHTML = "Medical Entity";
        lable.appendChild(newlable);

        let selectoption = document.getElementById("addInformations_input");
        const select = document.createElement("select");
        select.id = "select-institute-id-"+indexforinstituts;
        select.className = "select";
        select.style.width = "222px";
        select.style.background = "transparent";
        select.style.border = "2px solid #A5B9C3";
        select.style.color = "#fff";
        select.style.paddingLeft = "5px";
        select.style.paddingRight = "5px";
        select.value = selectedValues.dropdown2;
        select.onchange = (e) => handleSelectedInstitute(e, 'dropdown2');
        selectoption.appendChild(select);


        const fetchData = async () => {
            try {
                const raw = JSON.stringify({
                    "type": "Medical Entity"
                });
                const response = await fetch("https://sxk30zdmj1.execute-api.eu-central-1.amazonaws.com/prod/getallinstitutes", {
                    method: 'POST',
                    body: raw,
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': '*',
                        'Access-Control-Allow-Headers': '*',
                    },
                });
                const jsonData = await response.json();
                const parsed_body = JSON.parse(jsonData.body);
                let nulloption = document.createElement("option");
                nulloption.value = null;
                nulloption.text = "Select Institute";
                nulloption.style.color = "black";
                select.appendChild(nulloption);
                for (let i = 0; i < parsed_body.length; i++) {
                    let option = document.createElement("option");
                    option.value = parsed_body[i]["institutionID"];
                    option.text = parsed_body[i]["name"];
                    option.style.color = "black";
                    select.appendChild(option);
                }
            } catch (error) {
                console.error('Fehler beim Abrufen der Daten:', error);
            }
        };
        handelPlusPatientClick();
        fetchData();
    }

    function loadInstitutestype3() {
        const button = document.getElementById("plusInstitution");
        // Disable the button
        button.disabled = false;
        // const para = document.createElement("p");
        // para.innerHTML = "This is a paragraph.";
        // document.getElementById("institutes_list").appendChild(para);
        document.getElementById('addInformations').style.display='block';
        let lable = document.getElementById("addInformations_label");
        const newlable = document.createElement("p");
        newlable.innerHTML = "Clinical Entity";
        lable.appendChild(newlable);

        let selectoption = document.getElementById("addInformations_input");
        const select = document.createElement("select");
        select.id = "select-institute-id-"+indexforinstituts;
        select.className = "select";
        select.style.width = "222px";
        select.style.background = "transparent";
        select.style.border = "2px solid #A5B9C3";
        select.style.color = "#fff";
        select.style.paddingLeft = "5px";
        select.style.paddingRight = "5px";
        select.value = selectedValues.dropdown3;
        select.onchange = (e) => handleSelectedInstitute(e, 'dropdown3');
        selectoption.appendChild(select);


        const fetchData = async () => {
            try {
                const raw = JSON.stringify({
                    "type": "Clinical Entity"
                });
                const response = await fetch("https://sxk30zdmj1.execute-api.eu-central-1.amazonaws.com/prod/getallinstitutes", {
                    method: 'POST',
                    body: raw,
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': '*',
                        'Access-Control-Allow-Headers': '*',
                    },
                });
                const jsonData = await response.json();
                const parsed_body = JSON.parse(jsonData.body);
                let nulloption = document.createElement("option");
                nulloption.value = null;
                nulloption.text = "Select Institute";
                nulloption.style.color = "black";
                select.appendChild(nulloption);
                for (let i = 0; i < parsed_body.length; i++) {
                    let option = document.createElement("option");
                    option.value = parsed_body[i]["institutionID"];
                    option.text = parsed_body[i]["name"];
                    option.style.color = "black";
                    select.appendChild(option);
                }
            } catch (error) {
                console.error('Fehler beim Abrufen der Daten:', error);
            }
        };
        handelPlusPatientClick();
        fetchData();
    }

    const handlediscardbutton = () => {
        document.getElementById("addInformations_label").innerHTML = "";
        document.getElementById("addInformations_input").innerHTML = "";
        navigate("/process_page", { state: data_new });
    }

    const handleNewPatient = () => {
        window.alert("New Patient will be created!");
        changeNeeded = false;
        newpatient = true;
        let doc = document.getElementById("subject_database")
        doc.innerHTML = "A new subject will be created!"
        let doc3 = document.getElementById("change_note")
        doc3.innerHTML = "You have chosen to create a new subject, if you don't want it you have to start over."
        let doc4 = document.getElementById("patient_on_the_right_side");
        doc4.style.display = "none";
        let doc5 = document.getElementById("changebutton");
        doc5.style.background = "grey";
        doc5.style.border = "grey";
        doc5.setAttribute('disabled', 'disabled');
    }

    // Initialer Zustand, kein Wert ausgewählt, umbenannt zu 'chosenValue'
    const [chosenValue, setChosenValue] = useState('');

    // Initialer Zustand, kein Wert ausgewählt, umbenannt zu 'cloudGeneratedID'
    const [changed_cloudGeneratedID , setchanged_cloudGeneratedID] = useState('');

    // Initialer Zustand, kein Wert ausgewählt, umbenannt zu 'cloudGeneratedID'
    const [changed_subjectid , setchanged_subjectid] = useState('');

    // Funktion, die aufgerufen wird, wenn ein Radio-Button ausgewählt wird, mit aktualisiertem 'setChosenValue'
    const handleChange = (e) => {
        const value = e.target.value;
        setChosenValue(value);// Aktualisiere den Zustand mit dem neuen Wert, mit 'setChosenValue'
        setchanged_cloudGeneratedID(value.split('#')[0])
        setchanged_subjectid(value.split('#')[1])
        changeNeeded = false;
    };

    const [isVisible, setIsVisible] = useState(false);

    // const togglePopup = async () => {
    //     let doc3 = document.getElementById("change_note");
    //     console.log(doc3.innerText);
    //     if (doc3.innerText == "Patient found by patientIdByPhysician it is possible that more than one has been found.") {
    //         console.log("TEST!!");
    //     }
    //     if (chosenValue != "") {
    //         let doc2 = document.getElementById("subject_database")
    //         doc2.innerHTML = "Subject selected from database"
    //         let doc3 = document.getElementById("change_note")
    //         doc3.innerHTML = "Note: Subject ID "+changed_subjectid+" will be extended in the database with the uploaded log data and the implant serial number and with additional institute data you selected on the left side of the screen."
    //     }
    //     setIsVisible(!isVisible);
    // };

    function togglePopup() {
        if (chosenValue != "") {
            let doc2 = document.getElementById("subject_database")
            doc2.innerHTML = "Subject selected from database"
            let doc3 = document.getElementById("change_note")
            doc3.innerHTML = "Note: Subject ID "+changed_subjectid+" will be extended in the database with the uploaded log data and the implant serial number and with additional institute data you selected on the left side of the screen."
        }
        setIsVisible(!isVisible);
    }
    function togglePopupWithFilter() {
        for (const element of options) {
            if (element.value.split('#')[1] === obj.Patient_ID_from_csv) {
                optionsfilterbyid.push(element);
            }
        }
        options = optionsfilterbyid;
        if (chosenValue != "") {
            let doc2 = document.getElementById("subject_database")
            doc2.innerHTML = "Subject selected from database"
            let doc3 = document.getElementById("change_note")
            doc3.innerHTML = "Note: Subject ID "+changed_subjectid+" will be extended in the database with the uploaded log data and the implant serial number and with additional institute data you selected on the left side of the screen."
        }
        setIsVisible(!isVisible);
    }

    function animiere() {
        const ladebalken = document.getElementById('ladebalken');
        ladebalken.animate(
            [
                {
                    width: '1em',
                    background: 'rgb(188 203 74)'
                }, {
                width: '100%',
                background: 'rgb(188 203 74)'
            }
            ], {
                duration: 2000,
                iterations: 1,
                fill: 'forwards'
            });
    }

    const handleNavigation = (path) => {
        sessionStorage.clear();
        navigate(path); // Navigiere zur angegebenen Route
        window.location.reload(); // Lade die Seite neu, nachdem die Navigation erfolgt ist
    };

    return(
        <Authenticator>
            {({signOut }) => (
                <div>
                    <div className="signoutbtn">
                        <button onClick={signOut}>Sign Out</button>
                    </div>
                    <div className={"page_title"}>
                        <h1>UPLOAD PAGE</h1>
                    </div>
                    <main className={"main_process_page"}>

                        <div className="flex justify-between items-center">
                            <div className={"flex items-center"}>
                                <button style={{display:"flex", color:"#A5B9C3"}} onClick={handlePreviousClick}>
                                    <svg fill="#A5B9C3" height="24px" width="24px" viewBox="0 0 330 330" transform="rotate(180)">
                                        <path id="XMLID_222_" d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001 c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213 C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606 C255,161.018,253.42,157.202,250.606,154.389z"/>
                                    </svg>
                                    Previous
                                </button>
                                <pre>   </pre>
                                <button style={{display:"flex", color:"#A5B9C3"}} onClick={handleNextClick}>
                                    Next
                                    <svg fill="#A5B9C3" height="24px" width="24px" id="Layer_1" viewBox="0 0 330 330">
                                        <path id="XMLID_222_" d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001
                                c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213
                                C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606
                                C255,161.018,253.42,157.202,250.606,154.389z"/>
                                    </svg>
                                </button>
                            </div>
                            <div id={"filename"} style={{fontSize:"120%"}}>
                                {filename}
                                {/*<output id="ladebalken"> </output>*/}
                            </div>

                            <div>
                                {/*<a href="/upload" style={{display:"flex", color:"#A5B9C3"}}>*/}
                                    <Link to={"/"} onClick={() => handleNavigation('/')}>
                                        <svg fill="#A5B9C3" height="32px" width="32px" viewBox="0 0 460.775 460.775" >
                                            <path d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55
                                    c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55
                                    c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505
                                    c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55
                                    l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719
                                    c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z"/>
                                        </svg>
                                    </Link>

                                {/*</a>*/}
                            </div>
                        </div>


                        <div className="grid grid-rows-1 grid-flow-col gap-4 text-left">
                            <div className={"w-[130%]"}>
                                <div>
                                    <div className="horizontal-line w-[100%] mt-2 mb-2"></div>
                                </div>
                                <div>
                                    <div className="flex items-center">
                                        <h1 className={"font-bold text-[#A5B9C3]"}>From log-file</h1>
                                        <pre>   </pre>
                                        <button className={"rounded-button"} onClick={handleSaveClick}>Save</button>
                                    </div>
                                </div>
                                <div>
                                    <div className="horizontal-line w-[100%] mt-2 mb-2"></div>
                                </div>
                                <div>
                                    <div className="grid grid-cols-2 gap-4">
                                        <div>
                                            <p className="text-[#A5B9C3]">Subject ID</p>
                                        </div>
                                        <div>
                                            <p>{obj.Patient_ID_from_csv}</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="grid grid-cols-2 gap-4">
                                        <div>
                                            <p className="text-[#A5B9C3]">Implant SN</p>
                                        </div>
                                        <div>
                                            <p>{obj.EASEEPowerSN_from_csv}</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="horizontal-line w-[100%] mt-2 mb-2"></div>
                                </div>
                                <div id={"addInformations"} style={{display:"none"}} className={"max-w-0"}>
                                    <div className="grid grid-cols-3 gap-4">
                                        <div id={"addInformations_label"} className="text-[#A5B9C3] w-48">
                                            {/*Add Label for Institute via JS*/}
                                        </div>
                                        <div id="addInformations_input" className={"text-black ml-40"}>
                                            {/*Add Institutes via JS*/}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="dropdown ml-[-10px] mt-[10px]">
                                        <button className="dropbtn flex items-center">Add field
                                            <svg fill="#A5B9C3" width="25px" height="25px" transform="rotate(180)" viewBox="-2.4 -2.4 28.80 28.80">
                                                <path d="M21,21H3L12,3Z"/>
                                            </svg>
                                        </button>
                                        <div className="dropdown-content">
                                            <a>
                                                <button id={"plusInstitution"} onClick={loadInstitutestype1}>+ Institution (Precisis)</button>
                                            </a>
                                            <a>
                                                <button onClick={loadInstitutestype2}>+ Institution (Medical Entity)</button>
                                            </a>
                                            <a>
                                                <button onClick={loadInstitutestype3}>+ Institution (Clinical Study)</button>
                                            </a>
                                            {/*<a>*/}
                                            {/*    <button onClick={handelPlusPatientClick}>+ Subject ID</button>*/}
                                            {/*</a>*/}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="horizontal-line w-[100%] mt-2 mb-2"></div>
                                </div>
                                <div className={"mt-2"}>
                                    <div className="flex items-center">
                                        <h1 className={"font-bold text-[#A5B9C3]"}>remove log-file</h1>
                                        <pre>   </pre>
                                        <button className={"rounded-button"} onClick={handleDeleteClick}>Remove</button>
                                        <pre>   </pre>
                                        <button className={"rounded-button"} onClick={handlediscardbutton}>Discard all changes</button>
                                        {/*<button className={"rounded-button"} onClick={checkAddedFields}>Test</button>*/}
                                    </div>
                                </div>
                                <div>
                                    <div className="horizontal-line w-[100%] mt-2 mb-2"></div>
                                </div>
                            </div>

                            <div className={"ml-40 w-auto"}>
                                <div>
                                    <div className="horizontal-line w-[100%] mt-2 mb-2"></div>
                                </div>
                                <div>
                                    <div className="flex items-center">
                                        <h1 id={"subject_database"} className={"font-bold text-[#A5B9C3]"}>Subject found in database</h1>
                                        <pre>   </pre>
                                        {/*<button className={"rounded-button"} onClick={togglePopup} disabled style={{filter:"invert(0.30)"}}>Change</button>*/}
                                        <button id={"changebutton"} className={"rounded-button"} onClick={togglePopup}>Change</button>
                                    </div>
                                </div>
                                <div>
                                    <div className="horizontal-line w-[100%] mt-2 mb-2"></div>
                                </div>
                                {/*<div>*/}
                                {/*    <div className="grid grid-cols-2 gap-4">*/}
                                {/*        <div>*/}
                                {/*            <p className="text-[#A5B9C3]">cloudGeneratedID</p>*/}
                                {/*        </div>*/}
                                {/*        <div>*/}
                                {/*            <p>{objapi.cloudGeneratedID}</p>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div id={"patient_on_the_right_side"} style={{display: "block"}}>
                                    <div className="grid grid-cols-2 gap-4">
                                        <div id={"label_patient_found_in_database"} className="text-[#A5B9C3]">

                                        </div>
                                        <div id={"data_patient_found_in_database"}>

                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="horizontal-line w-[100%] mt-2 mb-2"></div>
                                </div>
                                <div>
                                    <div id="change_note"></div>
                                </div>
                            </div>

                            {isVisible && (
                                <div className="popup">
                                    <div className="popup-content">
                                        <span className="close" onClick={togglePopup}><svg fill="#A5B9C3" height="32px" width="32px" viewBox="0 0 460.775 460.775" >
                                            <path d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55
                                    c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55
                                    c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505
                                    c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55
                                    l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719
                                    c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z"/>
                                        </svg></span>

                                        <h1>Choose Subject</h1>
                                        <div id={"subject_list"} className={"scrollbox"}>
                                            {/*list of subjects*/}
                                            {options.map((option) => (
                                                <label key={option.value}>
                                                    <input
                                                        type="radio"
                                                        name="radioGroup"
                                                        value={option.value}
                                                        checked={chosenValue === option.value} // Verwendung von 'chosenValue' zum Überprüfen des ausgewählten Zustands
                                                        onChange={handleChange}
                                                    />
                                                    {option.label}
                                                    <br/>
                                                </label>
                                            ))}
                                        </div>
                                        <button className={"rounded-button"} onClick={togglePopup}>Select</button>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="horizontal-line w-[100%]"></div>
                    </main>
                </div>

            )}
        </Authenticator>
    );
}

export default Process_page;